.MuiPaper-elevation4{
    box-shadow: none!important;
}

.MuiDrawer-root.MuiDrawer-docked{
    margin-left: 50px;
    margin-top: 6%;
    margin-bottom: auto;
    border-radius: 30px;
    overflow: hidden;
    background-color: rgba(26,81,119,0.2)!important;
}



.MuiPaper-root{
    background-color: #fafafa!important;
}

.MuiAppBar-colorDefault{
    background-color: #fafafa!important;
}

.MuiDrawer-root .MuiPaper-root{
    /*background-color: rgba(26,81,119,0.2)!important;*/
    background-color: #91A3B8!important;
    color: #fff;
    z-index: 9999;
}

.MuiDrawer-root .MuiPaper-root .MuiIconButton-label,
.MuiDrawer-root .MuiPaper-root .MuiButtonBase-root,
.MuiDrawer-root .MuiPaper-root .MuiButtonBase-root svg{
    color: #fff;
}

.MuiFormLabel-root.Mui-focused{
    color:rgba(0, 0, 0, 0.54);
}

.makeStyles-menuButton-6{
    margin-left: 32px!important;
}

.logo{
    max-width: 200px;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 20px;
}

.MuiIconButton-colorInherit{
    color: #2E9B92!important;
}

header{
    z-index: -1!important;
}

@media (min-width: 600px){
    .makeStyles-drawerPaperClose-10{
        max-width:60px;
    }
}
