.rbc-events-container {
  margin-right: 0 !important;
}
.rbc-calendar {
  min-height: 500px;
}
.rbc-toolbar button {
  color: #373a3c;
  /* border: 1px solid rgba(26, 81, 119, 0.2) !important; */
  margin: 5px !important;
  padding: 7px 20px !important;
  border-radius: 25px !important;
  outline: none !important;
  cursor: pointer !important;
  /* background: rgba(26, 81, 119, 0.3) !important; */
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  color: #000 !important;
  border: 1px solid rgba(26,81,119,0.2) !important;
  background-color: rgba(26,81,119,0.2) !important;
}

.rbc-btn-group {
  text-align: center !important;
  white-space: initial !important;
}

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  border: none!important;
  min-height: 0;
  /*box-shadow: 0 0 16px 5px #1a517561;*/
  border-radius: 20px;
  margin-top: 20px;
}
.rbc-month-view{
  
  border-radius: 20px;
  margin-top: 20px;
}
.rbc-agenda-view {
  
  border-radius: 20px;
  margin-top: 20px;
}

body .rbc-header {
  padding: 12px 0;
  font-size: 17px;
  color: #1a5175;
  border: 0 !important;
  /* background: #f6f6f6; */
}

.rbc-time-gutter.rbc-time-column {
  padding: 12px 0;
  font-size: 16px;
  color: #1a5175;
  border: 0 !important;
  text-transform: uppercase !important;
  font-weight: 600;
}

.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: auto !important;
}

.rbc-time-header > div:first-child {
  width: 80px !important;
  min-width: inherit !important;
  max-width: none !important;
  border-bottom: 0 !important;
}
.rbc-time-header-content{
  border-left: 0 !important;
}

.rbc-calendar *, .rbc-calendar *:before, .rbc-calendar *:after {
  box-sizing: inherit;
  text-transform: capitalize;
}

.rbc-time-gutter.rbc-time-column > div {
  border-bottom: 0;
  width: 80px;
  text-align: center;
  align-items: center;
  font-weight: 550 ;
}

body .rbc-time-content{
    border-top:none;
}

body .rbc-time-content .rbc-day-slot:nth-child(2){
    -webkit-border-top-left-radius: 20px;
    -webkit-border-bottom-left-radius: 20px;
    -moz-border-radius-topleft: 20px;
    -moz-border-radius-bottomleft: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

body .rbc-time-content .rbc-day-slot:last-child{
    -webkit-border-top-right-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    -moz-border-radius-topright: 20px;
    -moz-border-radius-bottomright: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

body .rbc-time-content .rbc-day-slot{
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

body .rbc-events-container .rbc-event{
   -webkit-box-shadow: 2px 0px 14px -8px rgba(0,0,0,0.5); 
box-shadow: 2px 0px 14px -8px rgba(0,0,0,0.5);
}



.rbc-day-slot .rbc-event-content{
    width: auto!important;
    /* flex: 1 1; */
    word-wrap: break-word;
    /* line-height: 1; */
    height: auto!important; 
    /* min-height: 1em;*/
    flex:0 1!important;
}

.rbc-today{
    background-color: #f7f7f7!important;
}
.rbc-header.rbc-today{
    border-top: 2px solid #00ffc7!important;
}

.rbc-current-time-indicator{
        height: 5px!important;
    background: #00ffc7!important;
}

.rbc-current-time-indicator:before {
    content: "•";
    color: #00ffc7!important;
    margin-left: -9px;
    z-index: 99999;
    font-size: 50px;
    line-height: 5px;
}

.MuiPaper-rounded{
    background-color: #fafafa!important;
    box-shadow: none!important;
    border:none!important;
}

.rbc-time-slot .rbc-label{
    font-weight: normal;
    font-size: 10px;
    color: #6d6d6d;
}

.rbc-header span{
    color: #6d6d6d;
    text-transform: uppercase;
    font-weight: normal;
}

/* body .rbc-time-column .rbc-timeslot-group {
  flex: 1 1;
  padding-top: 0 !important;
  align-items: center;
  justify-content: center;
  display: flex;
}
body .rbc-time-slot {
  display: flex;
  align-items: flex-end;
} */

/* #3682B6: booked */
/* #66E2C3: planned */
/* #FDE250: pause */
