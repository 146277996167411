.sub_part{
    padding: 15px;
    box-shadow: 0 0 16px 5px #1a517561;
    border-radius: 14px;
}
.details_section{
    padding: 15px;
    box-shadow: 0 0 16px 5px #1a517561;
    border-radius: 14px;
    background: aliceblue;
    box-shadow: 0 0 6px 0px #1a517561;
}
.header_title{
    color: #1a5175;
    font-weight: 500 !important;
    margin-bottom: 15px;
}

.text_fields{
    margin-bottom: 10px;
}

.list_notes{
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.record_title{
    margin-right: 3px;
    font-weight: 600;
    white-space: pre;
    color: #1a5175;
    text-transform: capitalize;
}

.video_frame{
    height: 500px;
    width: 100%;
    border-radius: 8px;
}

.textfield_css{
    
}

/* fieldset.PrivateNotchedOutline-root-22.MuiOutlinedInput-notchedOutline {
    border: 1px solid #1a517517;
    background: aliceblue;
} */